.blocking-checkbox {
  width: auto;
  display: inline;

  input.blocking-checkbox-input,
  div.blocking-checkbox-label {
    display: inline-block;
  }

  input.blocking-checkbox-input {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }

  div.blocking-checkbox-label {
    width: auto;
    vertical-align: baseline;
  }
}
