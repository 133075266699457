@import "../../../scss/_bootstrap.scss";
@import "../../../scss/_bootstrap_overrides.scss";
@import "../../../scss/_variables.scss";

.blocking-status {
  margin-left: 60px;

  .blocking-status-offline,
  .blocking-status-online {
    margin-right: 10px;
  }

  .blocking-status-offline {
    color: $danger;
  }
  .blocking-status-online {
    color: $success;
  }

  .blocking-status-message {
    font-size: 1rem;
    font-weight: 500;
    margin-right: 10px;
  }

  .blocking-status-detail {
    font-size: 0.95rem;
    font-weight: 400;
  }
}
