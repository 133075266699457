.flyout {
  width: 0px;
  position: fixed;
  top: 48px;
  right: 0;
  background-color: color("detail-bg");
  border-left: 4px solid color("detail-border");
  height: calc(100% - 48px);
  min-width: 0px;
  overflow: none;
  z-index: 999;
  -webkit-box-shadow: 6px -20px 100px -3px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 6px -20px 100px -3px rgba(0, 0, 0, 0.5);
  box-shadow: 6px -20px 100px -3px rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  padding: 0px;
  align-content: center;

  &[data-open="true"] {
    width: space("flyout-w");
    opacity: 1;
    visibility: visible;
    pointer-events: initial;
    transition: min-width 0.3s ease-in-out, width 0.3s ease-in-out,
      opacity 0.5s linear, margin-right 0.1s linear;
  }

  .actions {
    margin-top: 25px;
  }

  &[data-static="true"] {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background: transparent;
  }

  .flyout-content {
    overflow: auto;
    height: 100%;
    display: block;
    padding: 20px;
    padding-bottom: 50px;
    background-color: color("detail-bg");
    background-image: url("/images/rightpanel_molecules.png");
    background-position: right top;
    background-size: 50%;
    background-repeat: no-repeat;

    .card-body {
      padding: 20px;
    }

    .field {
      min-width: 305px;
      max-width: 305px;
    }

    .notification-form {
      min-width: 305px;
      max-width: 305px;

      input[type="checkbox"] {
        width: auto;
        height: auto;
      }
    }

    .group-control-label {
      padding: 0 15px;
      padding-top: 10px;
    }

    input,
    input.form-control,
    select {
      height: 36px;
      width: 100%;
      font-size: 0.85rem;
      font-weight: 400;
      border: 1px solid hsl(0, 0%, 80%);
      border-radius: 4px;
      padding: 2px 8px;
    }

    .react-select {
      display: flex;
      flex-direction: column;
    }
    .react-select > div {
      align-content: center;

      input {
        height: unset;
      }

      div + div > span {
        margin-top: 12px;
        margin-bottom: 12px;
      }
    }
  }

  .close {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0.7rem;
    font-size: 1.3rem;
    z-index: 1;
    color: #212529;
  }

  p,
  .explanation {
    margin: 24px 0;
    @include font("flyout-explanation");
  }

  // Sections within flyouts are all spaced 24px apart
  section {
    margin: space("flyout-section-v-gap") 0;
  }

  .flyout-input-group {
    padding: 15px;

    // Make sure the text areas are nice and big
    textarea {
      width: 100%;
      min-height: 6em;
    }

    input {
      width: 100%;
    }
  }
}

.flyout-expandable-details {
  position: relative;

  @include font("flyout-body");

  .row {
    margin-left: 0px;
  }

  .flyout-expandable-details-button.button-open {
    background-color: darken($primary, 8%);
  }
  .flyout-expandable-details-button.button-open:hover {
    background-color: $primary;
  }

  .flyout-expandable-details-button {
    .flyout-expandable-details-text {
      margin-right: space("sm");
    }
  }

  .flyout-expandable-details-contents {
    margin-top: 0;
  }
}
