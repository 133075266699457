.workflow
  .disabledWorkflow
    width: 100%
    height: 100%
    background: rgba(0, 0, 0, 0.05)

  .workflowOverlay
    display: flex
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    flexDirection: column
    align-items: center
    justify-content: center

  .question
    position: relative

    &:not(:last-of-type)
      margin-bottom: 30px

    label, .questionText
      display: block
      box-sizing: border-box
      border: 2px solid #ccc
      border-left: none
      font-size: 16px

    .questionRow
      display: flex
      width: 100%
      flex-direction: row
      margin-bottom: 10px

      .questionKey
        min-width: 20px
        display: flex
        text-align: center
        color: white
        background-color: #16a085
        font-weight: bold
        flex-direction: column
        justify-content: center
      .questionText
        padding-left: 10px
        width: 100%
        overflow-wrap: anywhere


    .questionRow.active
      .questionText
        border-color: #16a085

    label .answerLabel
      max-height: 0
      opacity: 0
      font-size: 0.85em
      padding-left: 35px
      color: #666

    &:not(.answered) label:hover .answerLabel
      max-height: 50px
      opacity: 1
      transition: all ease 0.7s

    button.submitAnswer
      width: calc(100% - 20px)
      height: 35px
      margin-left: 20px
      background-color: #16a085
      color: white
      font-size: 0.9em
      cursor: pointer

      &:hover
        background-color: #139078


    label
      border-left: 2px solid #ccc
      cursor: pointer
      margin-left: 20px

      div.answer
        display: flex
        padding: 2px 0

        .answerText
          font-size: 16px
          display: inline-flex
          align-content: flex-start
          overflow-wrap: anywhere

      input[type="radio"]
        visibility: hidden
        display: inline-block
        line-height: 16px

      input[type="radio"] + .answerText::before
          content: ''
          margin-right: 6px
          margin-left: -5px
          margin-bottom: 1px
          align-self: center
          background-color: #eee
          display: inline-flex
          visibility: visible
          border: 2px solid #ccc
          min-width: 16px
          max-width: 16px
          min-height: 16px
          max-height: 16px
          border-radius: 16px

      &:hover
        border: 2px solid #16a085

        input[type="radio"] + .answerText::before
          border: 2px solid #16a085

        .answerLabel
          display: block

      input[type="radio"]:checked + .answerText::before
          background-color: #16a085
          border: 2px solid #16a085

    label.answered
      cursor: default
      border: 2px solid #16a085
