.flyout-loading {
  text-align: center;

  .loading-wrapper {
    margin: 60px 0;
  }

  .loading-message {
    @include font("headline");
  }
}
