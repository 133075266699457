@import "../../../scss/_variables.scss";

.sensor-module-meta-item {
  margin-top: 25px;
  margin-bottom: 25px;

  .fa.fa-question-circle {
    color: $base-blue;
  }
}
