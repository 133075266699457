.alert.alert-dismissible {
  // We have our own dismissal mechanism
  .close {
    display: none;
  }

  div {
    margin-top: 10px;
  }
}

.alert {
  @include font("alert-body");

  @each $color, $value in $theme-colors {
    .btn-#{$color} {
      @include button-variant(darken($value, 10%), #ffffff);
    }
  }

  .alert-subtitle {
    font-size: 0.75em; // Relative to the body
    font-weight: bold;
    margin: 10px 0;
  }

  .alert-details {
    font-size: 0.75em; // Relative to the body
  }
}
