.sensor-description {
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 10px 20px;

  .sensor-description-add {
    margin-right: 7px;
  }
}
