@import "../../../../scss/_variables.scss";

.resources-table {
  max-width: min-content;

  .resources-table-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-left {
      flex: 1 1 200px;
    }

    .title-right {
      flex: 2 1 250px;
      min-width: 250px;
      margin: 20px 0;
    }
  }

  .number-column {
    text-align: right;
  }

  .ReactTable .rt-th {
    overflow: visible;
  }

  .datagrid {
    .ReactTable {
      border: none;
      overflow-wrap: break-word;
      word-break: break-word;

      .rt-th,
      .rt-td {
        white-space: normal;
      }

      .rt-table {
        .rt-thead {
          &.-header {
            padding-top: 10px;
            box-shadow: none;
          }

          .rt-th,
          .rt-td {
            border: none;
          }

          &.-filters,
          &.-filters .rt-th {
            border: none;
          }

          &.-filters input,
          &.-filters button {
            border: none;
            border-bottom: 1px solid color("border-resources");
            font-size: 0.7rem;
            height: 100%;
            padding: 5px 0;
            width: 100%;
          }

          &.-filters {
            button,
            button:active,
            button:focus,
            button:hover,
            input,
            input:active,
            input:focus,
            input:hover {
              border: none;
              border-bottom: 1px solid color("border-resources");
              border-radius: 0;
            }

            .dropdown-menu {
              padding: 0.5rem 0;

              button.dropdown-item {
                border-bottom: none;
                padding: 0.25rem 0.5rem;

                &:active,
                &:focus {
                  border: none;
                  outline: none;
                }

                &:active {
                  background-color: $icon-color-grey;
                }
              }
            }
          }

          .rt-tr {
            background-color: $background-color-white;
            color: $text-color-main;

            .rt-th {
              padding: 5px 15px 5px 0;
            }
          }

          .rt-tr .rt-th {
            padding: 5px 15px 5px 0;
          }
        }

        .rt-tbody {
          border: none;
          border-bottom: 1px solid color("border-resources");
          border-top: 1px solid color("border-resources");

          .rt-td {
            border: none;
            color: $text-color-main;
          }

          .rt-tr {
            .rt-td {
              padding: 5px 15px 5px 0;
            }

            &.-odd {
              background-color: $background-color-white;
            }
          }

          .rt-tr-group {
            border-bottom: none;
          }
        }
      }

      .-pagination {
        color: $text-color-main;

        .-btn:not([disabled]):hover {
          background-color: $icon-color-grey;
          color: $text-color-main;
        }

        input,
        select {
          border: none;
          border-radius: 0;
          font-weight: normal;
          height: 1.5rem;
          outline: none;
          padding: 5px 7px;
        }

        input {
          border-bottom: 1px solid color("border-resources");
          text-align: center;
          width: 70px;
        }
      }
    }
  }
}
