@import "../../../scss/_variables.scss";

.sensor-header {
  display: flex;
  justify-content: space-between;
  margin: 10px 20px;
  min-height: 36px;

  .sensor-header-left,
  .sensor-header-right {
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sensor-header-left {
    color: $base-blue;
    font-weight: 500;
    font-size: 1.5rem;
  }

  .sensor-name {
    display: inline-block;
    vertical-align: middle;
    overflow-wrap: break-word;
    word-break: break-word;
  }
}
