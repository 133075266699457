.sensor-modal {
  .modal-content {
    margin-bottom: 60px;
  }

  .sensor-modal-header {
    color: white;
    background-color: #365c86;

    button.close {
      color: white;
      opacity: 1;
    }
  }

  .sensor-modal-body {
    background-color: #efefef;
    text-align: left;

    .sensor-modal-loading-container {
      padding: 0 15px;
      text-align: center;
    }

    .sensor-subtitle {
      color: #3b3b3b;
      margin-bottom: 15px;
      font-weight: bold;
      font-size: 0.9rem;
      text-align: center;
    }
  }

  .sensor-modal-footer {
    background-color: white;

    .sensor-modal-loading-btn {
      min-width: 70px;
    }
  }

  button {
    min-width: 70px;
  }
}
