@import "./charts/containers/threats_opened_closed.scss";
@import "./charts/containers/suspects_opened_resolved.scss";

.chart-container {
  display: inline-flex;
  text-align: center;
  vertical-align: top;
  width: 100%;
  align-items: center;
  justify-content: center;

  .stats {
    width: 15%;
    max-width: 200px;
    display: inline-block;
  }

  .barchart {
    width: calc(100% - 15%);
    display: inline-block;
  }

  &.title {
    color: $link-primary;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    vertical-align: top;
  }
}

.chart {
  /**
   * World Choropleth Map
   */
  .world-map {
    path.countries {
      stroke-width: 1;
      stroke: #ffffff;
      fill: #e7d3d2;
    }
  }

  .world-map-tooltip {
    font-size: 15px;
    font-weight: 300;
    position: fixed;
    z-index: 100;
    display: block;
    border: 1px solid #c3c0c0;
    background-color: white;
    padding: 0 4px;
  }

  .stacked-bar {
    .axis {
      font-size: 14px;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 5px 5px;
    font-size: 14px;
  }
}
