.resources-search {
  display: flex;
  flex-direction: row;
  max-width: 800px;
  font-size: 0.9rem;
}

.resources-search-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: hsl(212, 65%, 52%);
  color: white;
  border-top: 1px solid hsl(212, 65%, 52%);
  border-bottom: 1px solid hsl(212, 65%, 52%);
  border-left: 1px solid hsl(212, 65%, 52%);
  border-radius: 4px 0 0 4px;
}

.resources-search-select {
  min-width: 200px;
  max-width: 200px;
}

.resources-search-right {
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  border-right: 1px solid rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 0 4px 4px 0;

  input {
    border-radius: 0 4px 4px 0;
  }
}

.resources-search-input {
  height: 38px;
}

.resources-search-input,
.resources-search-input:focus {
  border: none;
  box-shadow: none;
}
