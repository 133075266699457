.main-content-wrapper > .my-account-view {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.my-account-view {
  padding: 20px 5px 20px 20px;

  .field {
    margin-bottom: 10px;
    width: 50%;
    min-width: 250px;
    flex-grow: 1;
  }

  .field {
    padding-right: 15px;
  }

  .my-account-header {
    position: relative;
    margin-right: 15px;
    vertical-align: middle;

    .avatar-container {
      display: inline-block;
      vertical-align: middle;
    }

    h1 {
      display: inline-block;
    }

    .avatar {
      margin-right: 20px;
      border-radius: 60px;
      font-size: 20px;
      min-width: 60px;
      max-width: 60px;
      min-height: 60px;
      max-height: 60px;

      img.avatar-content {
        min-width: 60px;
        max-width: 60px;
        min-height: 60px;
        max-height: 60px;
      }
    }
  }

  .tosLink {
    margin-bottom: 15px;
  }
}
