.login {
  min-width: 350px;
  padding-top: 10px;
  background-color: #f4f6f8;

  .auth0-lock-center .auth0-lock-widget .auth0-lock-header {
    display: none;
  }

  input[type="text"],
  input[type="password"] {
    width: 100%;
    border-radius: 0px;
    border: 1px solid #a9a9a9;
    padding-top: 36px;
    padding-left: 24px;
    padding-bottom: 14px;
    font-size: 20px;
    color: #333333;

    &:focus {
      border-left: 5px solid #16a085;
      padding-left: 19px;
      outline: none;
    }
  }

  input[type="checkbox"] {
    font-size: 18px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    zoom: 1.1;
    width: 32px;
    height: 32px;
  }

  label.inline {
    position: absolute;
    left: 24px;
    top: 20px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
    color: #999;
  }

  .form-group {
    margin: 0px;
  }

  .btn {
    &.btn-primary {
      background-color: #2980b9;
      padding: 16px;
      border: 1px solid #fff;
      font-size: 14px;
    }
  }
}
