// Make a button look and behave like a link
.add-new-button {
  font-weight: $font-weight-light;
  cursor: pointer;

  span {
    font-weight: 500;
    font-size: 12px;
    margin-right: space("sm");
  }
}
