span.blumira-form-help,
form .field span.blumira-form-help {
  display: inline;
  width: 20px;
  margin: 0 10px;
  vertical-align: baseline;
}

.blumira-help-tooltip {
  max-width: 300px !important;
  color: black !important;
  background-color: white !important;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  text-align: left !important;
  font-size: 0.8rem;
}
