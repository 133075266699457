@import "../../../scss/_bootstrap.scss";
@import "../../../scss/_bootstrap_overrides.scss";

.tag-btn {
  display: inline-flex;
  align-content: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 3px 5px 3px 0;
  justify-content: space-between;
  cursor: pointer;
  background-color: #ccd3d6;
  border: 1px solid #6a7b89;

  .btn.disabled,
  .btn:disabled {
    opacity: 1;
  }

  .tag-name {
    padding: 1px 5px;
  }

  .tag-name,
  button.delete-btn {
    background-color: #ccd3d6;
    color: hsl(0, 0%, 20%);
    border: none;
    border-radius: 0px;
    font-size: 0.8rem;
    display: flex;
    height: 100%;
  }

  button.delete-btn {
    &:hover {
      background-color: #ffbdad;
      color: #de350b;
    }
  }
}
