.loadingTableOuterContainer {
  width: 100%;

  .loadingTableRowContainer {
    height: 61px;
    display: flex;
    padding: 0 15px;
    align-items: center;
    justify-content: flex-start;

    // this portion is for older tables
    // and will be removed eventually
    &.whiteBackground {
      height: 45px;
      background-color: white;
    }

    &.darkBackground {
      height: 45px;
      background-color: #f9f9f9;
    }

    &.darkerBackground {
      height: 45px;
      background-color: #d6dbdf;
    }
  }
}
