@import "../../../scss/_variables.scss";

.sensor-modal-content {
  .sensor-form-item {
    margin: 20px 0;
  }

  .sensor-label {
    color: $base-blue;
    margin-bottom: 7px;
    font-weight: bold;
    font-size: 0.85rem;
  }

  .sensor-select {
    margin-bottom: 20px;
  }

  input.sensor-input,
  textarea.sensor-textarea {
    width: 100%;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    color: hsl(0, 0%, 20%);
    padding-left: 7px;
    padding-right: 7px;
    max-width: calc(100% - 8px);
  }

  input.sensor-input {
    min-height: 36px;
  }

  textarea.sensor-textarea {
    min-height: 100px;
  }
}
