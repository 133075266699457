.event {
  .main-content {
    .column-header {
      margin-top: 20px;
      font-weight: 300;
      font-size: 14px;
      padding-bottom: 4px;
      text-transform: uppercase;
      color: #34495e;
    }

    .left-column {
      padding: 0px;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding-bottom: 100px;
      display: block;
      overflow: auto;

      .event-card {
        padding: 0px 48px;
      }

      .message {
        width: 100%;
        border-top: 1px solid #a9a9a9;
        border-bottom: 1px solid #a9a9a9;
        background-color: #ebedef;
        min-height: 72px;
        padding: 10px 48px;

        textarea {
          width: 100%;
          height: 46px;
          padding: 10px;
          border: none;
          outline: none;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          display: block;
          resize: none;
          border: 1px solid #a9a9a9;

          &:focus {
            outline: none;
          }
        }
      }

      .timeline-wrapper {
        width: 100%;
        .column-header {
          padding: 0px 48px;
        }
      }
    }
    .right-column {
      padding: 20px;
      border-left: 4px solid $background-color-selected;
      background-color: $background-color-grey-accent-lighter;
      min-height: 400px;
      padding-bottom: 100px;
      overflow: auto;
    }
  }
}
