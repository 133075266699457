@import "../../../scss/_bootstrap.scss";
@import "../../../scss/_bootstrap_overrides.scss";
@import "../../../scss/_variables.scss";

.sensor-modules-container {
  margin-top: 20px;
  margin-bottom: 20px;

  .sensor-modules-none-found {
    .none-found-bold {
      font-weight: bold;
      margin-right: 7px;
    }
  }

  .modules-cell {
    white-space: normal;
    word-wrap: break-word;
  }

  .modules-cell-bold {
    font-weight: bold;
  }

  .modules-ul {
    padding-left: 5px;
  }

  .modules-ul-no-bullets {
    list-style-type: none;
  }

  .modules-status {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    max-width: 75px;

    div {
      display: inline-block;
    }

    .status-dot-green,
    .status-dot-yellow,
    .status-dot-red,
    .status-dot-gray {
      padding-right: 5px;
    }

    .status-dot-green {
      color: $success;
    }
    .status-dot-yellow {
      color: $warning;
    }
    .status-dot-red {
      color: $danger;
    }
    .status-dot-gray {
      color: $light;
    }
  }
}
