@import "../../../scss/_bootstrap.scss";
@import "../../../scss/_bootstrap_overrides.scss";
@import "../../../scss/_variables.scss";

.sensor-overview-container {
  margin-top: 20px;
  margin-bottom: 20px;

  .overview-cell {
    white-space: normal;
    word-wrap: break-word;
  }

  .overview-cell-bold {
    font-weight: bold;
  }

  .overview-ul {
    padding-left: 15px;
  }
}
