@import "../../../scss/_bootstrap.scss";
@import "../../../scss/_bootstrap_overrides.scss";

.reset-demo-container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;

  .resetting-demo-div {
    width: 100%;
    min-width: max-content;
    text-align: center;
    color: $primary;
    margin-bottom: 10px;
  }

  .reset-demo-btn {
    float: right;
    background-color: $primary;
  }

  .reset-demo-error {
    width: 100%;
    min-width: max-content;
  }
}
