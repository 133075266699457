/**
 * Layout, text, icons, etc.
 */
html,
body,
#root {
  width: 100%;
  min-width: 605px;
  display: flex;
  min-height: 100vh;
  font-size: 1rem;
  transition: all 0.25s linear;
}

body {
  background: #ffffff;
  color: $text-color-main;
  font-family: $font-family-primary;
  font-weight: 300;
}

body,
div#root {
  min-width: 520px;
}

[role="button"] {
  &:focus {
    outline: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}

input,
button,
.btn {
  font-weight: 300;
}

.btn.btn-sm {
  font-size: 0.7rem;
}

[role="button"] {
  cursor: pointer;
}

.fill {
  flex: 1;
}

.fill-1 {
  flex: 1 0 auto;
}

.padding16 {
  padding: 0px 16px;
}

.header {
  background-color: $header-background-color;
  width: 100%;
  padding: 0;
  min-width: 480px;
  font-size: 0.8rem;

  span,
  button {
    font-size: 0.8rem;
  }

  ul.dropdown-menu {
    background-color: $header-background-color;
    color: $text-color-active;
    left: -9px;
    top: 39px;
    padding: 10px 20px;
    font-size: 0.8rem;

    a {
      color: $text-color-active;
    }
  }

  .menu-item {
    height: 48px;
    display: inline-block;
    border-right: 1px solid $border-color-secondary;
    position: relative;
    vertical-align: top;

    &.logo {
      width: 300px;
      background-color: $header-background-color-darker;
    }

    .menu-item-span {
      display: inline-block;
      color: #ffffff;
      padding: 10px 24px;

      .title {
        position: relative;
        top: -2px;
      }

      i {
        color: $icon-color;
        font-size: 18px;
      }

      > .btn,
      input {
        color: $text-color-secondary;
      }

      input.white-placeholder {
        &::placeholder {
          color: $text-color-secondary;
        }
      }
    }

    &.user {
      border-left: 1px solid $border-color-secondary;

      .dropdown-menu {
        top: 37px;
        margin-right: 20px;
        left: -26px;
        text-align: right;
      }
    }
  }
}

.sidebar {
  display: flex;

  .sidebar-left-wrapper {
    display: inline-flex;
    flex-direction: column;
    background: #001642;
    border-right: 5px solid $background-color-selected;
    -webkit-box-shadow: 6px -1px 24px -3px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 6px -1px 24px -3px rgba(0, 0, 0, 0.5);
    box-shadow: 6px -1px 24px -3px rgba(0, 0, 0, 0.5);

    .top-level-menu-item {
      padding-left: 12px;
      width: 160px;
      color: $text-color-active;
      font-size: 17px;
      line-height: 65px;
      height: 65px;
      border-bottom: 1px solid $border-color-secondary;
      cursor: pointer;
      min-width: 100%;

      .icon {
        width: 20px;
        text-align: center;
        display: inline-block;
        opacity: 0.9;

        i.fa-search {
          position: relative;
          left: 3px;
        }
      }

      .title {
        vertical-align: middle;
        margin-left: 10px;
        font-weight: 300;
      }

      .badge {
        font-weight: 600;
        font-variant-caps: all-small-caps;
        color: #cd242b;
      }

      &:hover {
        color: $text-color-active;
        background-color: $background-color-selected;
      }

      &.active {
        color: $text-color-active;
        background-color: $background-color-selected;

        .icon {
          opacity: 1;
          color: $text-color-active;
        }
      }
    }
  }

  .sidebar-right-wrapper {
    background: linear-gradient(to right, #142330, #34495e);
    display: inline-flex;
    flex-direction: column;

    .second-level-menu-item {
      color: #ffffff;
      cursor: pointer;
      padding-left: 12px;
      font-size: 17px;
      height: 65px;
      width: 204px;
      line-height: 65px;
      border-bottom: 1px solid $border-color-tertiary;

      .title {
        vertical-align: middle;
        font-weight: 300;
      }

      .badge {
        font-weight: 600;
        font-variant-caps: all-small-caps;
        padding-bottom: 4px;
        color: #cd242b;
      }

      &:hover {
        opacity: 0.75;
        background-color: #445868;
      }

      &.active {
        opacity: 1;
        background-color: #445868;
      }
    }
  }
}

@media only screen and (max-width: 1092px) {
  .header {
    .logo {
      width: 206px !important;
      min-width: 206px !important;

      .button {
        right: 26px !important;
      }
    }
  }
  .sidebar {
    width: 205px !important;
    overflow: hidden;

    .sidebar-left-wrapper {
      overflow: hidden;
      width: 72px;
      flex-grow: 100;
      align-items: center;
    }

    .sidebar-right-wrapper {
      max-width: min-content;
      display: inline-block;
    }

    .sidebar-right-wrapper > div:empty {
      max-width: 0px;
    }

    .top-level-menu-item {
      text-align: center;
      padding-left: 0 !important;
      overflow: hidden;
      max-width: min-content;

      .icon {
        width: 25px !important;
        font-size: 24px !important;
      }

      .title {
        min-width: 100px;
        display: none;
      }
    }

    .second-level-menu-item {
      min-width: 120px;
    }
  }
}

.form-group {
  position: relative;

  .input-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    color: $icon-color;
  }
}

.main-content-wrapper {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 200px;
  flex: 1 0;
  background-image: url("/images/bg_02@2x.png");
  background-size: contain;
  background-position: center;

  overflow: auto;

  > .alert {
    font-size: 1.2rem;
    border-radius: 0px;
    text-align: center;
  }

  h1 {
    color: $text-color-accent;
    font-size: 1.4rem;
    font-weight: 300;
  }

  hr {
    margin-top: 0.5rem;
    border-color: $border-color-tertiary;
  }

  .page-header {
    vertical-align: bottom;
    display: flex;

    h1 {
      margin: 0;
      padding: 0;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      padding-right: 10px;

      p {
        margin: 0;
        padding: 0;
      }
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.footer-gradient {
  display: none;
}

@keyframes scale-shake {
  0% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  1.666667% {
    -webkit-transform: matrix3d(
      1.16971,
      0,
      0,
      0,
      0,
      1.16971,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.16971,
      0,
      0,
      0,
      0,
      1.16971,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  3.333333% {
    -webkit-transform: matrix3d(
      1.27101,
      0,
      0,
      0,
      0,
      1.27101,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.27101,
      0,
      0,
      0,
      0,
      1.27101,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  5% {
    -webkit-transform: matrix3d(
      1.31969,
      0,
      0,
      0,
      0,
      1.31969,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.31969,
      0,
      0,
      0,
      0,
      1.31969,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  6.666667% {
    -webkit-transform: matrix3d(
      1.32993,
      0,
      0,
      0,
      0,
      1.32993,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.32993,
      0,
      0,
      0,
      0,
      1.32993,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  8.333333% {
    -webkit-transform: matrix3d(
      1.31385,
      0,
      0,
      0,
      0,
      1.31385,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.31385,
      0,
      0,
      0,
      0,
      1.31385,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  10% {
    -webkit-transform: matrix3d(
      1.28134,
      0,
      0,
      0,
      0,
      1.28134,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.28134,
      0,
      0,
      0,
      0,
      1.28134,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  11.666667% {
    -webkit-transform: matrix3d(
      1.24015,
      0,
      0,
      0,
      0,
      1.24015,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.24015,
      0,
      0,
      0,
      0,
      1.24015,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  13.333333% {
    -webkit-transform: matrix3d(
      1.19603,
      0,
      0,
      0,
      0,
      1.19603,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.19603,
      0,
      0,
      0,
      0,
      1.19603,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  15% {
    -webkit-transform: matrix3d(
      1.15302,
      0,
      0,
      0,
      0,
      1.15302,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.15302,
      0,
      0,
      0,
      0,
      1.15302,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  16.666667% {
    -webkit-transform: matrix3d(
      1.11374,
      0,
      0,
      0,
      0,
      1.11374,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.11374,
      0,
      0,
      0,
      0,
      1.11374,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  18.333333% {
    -webkit-transform: matrix3d(
      1.07967,
      0,
      0,
      0,
      0,
      1.07967,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.07967,
      0,
      0,
      0,
      0,
      1.07967,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  20% {
    -webkit-transform: matrix3d(
      1.05144,
      0,
      0,
      0,
      0,
      1.05144,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.05144,
      0,
      0,
      0,
      0,
      1.05144,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  21.666667% {
    -webkit-transform: matrix3d(
      1.02905,
      0,
      0,
      0,
      0,
      1.02905,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.02905,
      0,
      0,
      0,
      0,
      1.02905,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  23.333333% {
    -webkit-transform: matrix3d(
      1.01212,
      0,
      0,
      0,
      0,
      1.01212,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.01212,
      0,
      0,
      0,
      0,
      1.01212,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  25% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  26.666667% {
    -webkit-transform: matrix3d(
      0.99192,
      0,
      0,
      0,
      0,
      0.99192,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.99192,
      0,
      0,
      0,
      0,
      0.99192,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  28.333333% {
    -webkit-transform: matrix3d(
      0.9871,
      0,
      0,
      0,
      0,
      0.9871,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.9871,
      0,
      0,
      0,
      0,
      0.9871,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  30% {
    -webkit-transform: matrix3d(
      0.98478,
      0,
      0,
      0,
      0,
      0.98478,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.98478,
      0,
      0,
      0,
      0,
      0.98478,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  31.666667% {
    -webkit-transform: matrix3d(
      0.9843,
      0,
      0,
      0,
      0,
      0.9843,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.9843,
      0,
      0,
      0,
      0,
      0.9843,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  33.333333% {
    -webkit-transform: matrix3d(
      0.98506,
      0,
      0,
      0,
      0,
      0.98506,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.98506,
      0,
      0,
      0,
      0,
      0.98506,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  35% {
    -webkit-transform: matrix3d(
      0.98661,
      0,
      0,
      0,
      0,
      0.98661,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.98661,
      0,
      0,
      0,
      0,
      0.98661,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  36.666667% {
    -webkit-transform: matrix3d(
      0.98857,
      0,
      0,
      0,
      0,
      0.98857,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.98857,
      0,
      0,
      0,
      0,
      0.98857,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  38.333333% {
    -webkit-transform: matrix3d(
      0.99067,
      0,
      0,
      0,
      0,
      0.99067,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.99067,
      0,
      0,
      0,
      0,
      0.99067,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  40% {
    -webkit-transform: matrix3d(
      0.99272,
      0,
      0,
      0,
      0,
      0.99272,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.99272,
      0,
      0,
      0,
      0,
      0.99272,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  41.666667% {
    -webkit-transform: matrix3d(
      0.99459,
      0,
      0,
      0,
      0,
      0.99459,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.99459,
      0,
      0,
      0,
      0,
      0.99459,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  43.333333% {
    -webkit-transform: matrix3d(
      0.99621,
      0,
      0,
      0,
      0,
      0.99621,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.99621,
      0,
      0,
      0,
      0,
      0.99621,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  45% {
    -webkit-transform: matrix3d(
      0.99755,
      0,
      0,
      0,
      0,
      0.99755,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.99755,
      0,
      0,
      0,
      0,
      0.99755,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  46.666667% {
    -webkit-transform: matrix3d(
      0.99862,
      0,
      0,
      0,
      0,
      0.99862,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.99862,
      0,
      0,
      0,
      0,
      0.99862,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  48.333333% {
    -webkit-transform: matrix3d(
      0.99942,
      0,
      0,
      0,
      0,
      0.99942,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.99942,
      0,
      0,
      0,
      0,
      0.99942,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  50% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  51.666667% {
    -webkit-transform: matrix3d(
      1.00038,
      0,
      0,
      0,
      0,
      1.00038,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.00038,
      0,
      0,
      0,
      0,
      1.00038,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  53.333333% {
    -webkit-transform: matrix3d(
      1.00061,
      0,
      0,
      0,
      0,
      1.00061,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.00061,
      0,
      0,
      0,
      0,
      1.00061,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  55% {
    -webkit-transform: matrix3d(
      1.00072,
      0,
      0,
      0,
      0,
      1.00072,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.00072,
      0,
      0,
      0,
      0,
      1.00072,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  56.666667% {
    -webkit-transform: matrix3d(
      1.00075,
      0,
      0,
      0,
      0,
      1.00075,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.00075,
      0,
      0,
      0,
      0,
      1.00075,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  58.333333% {
    -webkit-transform: matrix3d(
      1.00071,
      0,
      0,
      0,
      0,
      1.00071,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.00071,
      0,
      0,
      0,
      0,
      1.00071,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  60% {
    -webkit-transform: matrix3d(
      1.00064,
      0,
      0,
      0,
      0,
      1.00064,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.00064,
      0,
      0,
      0,
      0,
      1.00064,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  61.666667% {
    -webkit-transform: matrix3d(
      1.00054,
      0,
      0,
      0,
      0,
      1.00054,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.00054,
      0,
      0,
      0,
      0,
      1.00054,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  63.333333% {
    -webkit-transform: matrix3d(
      1.00044,
      0,
      0,
      0,
      0,
      1.00044,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.00044,
      0,
      0,
      0,
      0,
      1.00044,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  65% {
    -webkit-transform: matrix3d(
      1.00035,
      0,
      0,
      0,
      0,
      1.00035,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.00035,
      0,
      0,
      0,
      0,
      1.00035,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  66.666667% {
    -webkit-transform: matrix3d(
      1.00026,
      0,
      0,
      0,
      0,
      1.00026,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.00026,
      0,
      0,
      0,
      0,
      1.00026,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  68.333333% {
    -webkit-transform: matrix3d(
      1.00018,
      0,
      0,
      0,
      0,
      1.00018,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.00018,
      0,
      0,
      0,
      0,
      1.00018,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  70% {
    -webkit-transform: matrix3d(
      1.00012,
      0,
      0,
      0,
      0,
      1.00012,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.00012,
      0,
      0,
      0,
      0,
      1.00012,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  71.666667% {
    -webkit-transform: matrix3d(
      1.00007,
      0,
      0,
      0,
      0,
      1.00007,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.00007,
      0,
      0,
      0,
      0,
      1.00007,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  73.333333% {
    -webkit-transform: matrix3d(
      1.00003,
      0,
      0,
      0,
      0,
      1.00003,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      1.00003,
      0,
      0,
      0,
      0,
      1.00003,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  75% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  76.666667% {
    -webkit-transform: matrix3d(
      0.99998,
      0,
      0,
      0,
      0,
      0.99998,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.99998,
      0,
      0,
      0,
      0,
      0.99998,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  78.333333% {
    -webkit-transform: matrix3d(
      0.99997,
      0,
      0,
      0,
      0,
      0.99997,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.99997,
      0,
      0,
      0,
      0,
      0.99997,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  80% {
    -webkit-transform: matrix3d(
      0.99997,
      0,
      0,
      0,
      0,
      0.99997,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.99997,
      0,
      0,
      0,
      0,
      0.99997,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  81.666667% {
    -webkit-transform: matrix3d(
      0.99996,
      0,
      0,
      0,
      0,
      0.99996,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.99996,
      0,
      0,
      0,
      0,
      0.99996,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  83.333333% {
    -webkit-transform: matrix3d(
      0.99997,
      0,
      0,
      0,
      0,
      0.99997,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.99997,
      0,
      0,
      0,
      0,
      0.99997,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  85% {
    -webkit-transform: matrix3d(
      0.99997,
      0,
      0,
      0,
      0,
      0.99997,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.99997,
      0,
      0,
      0,
      0,
      0.99997,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  86.666667% {
    -webkit-transform: matrix3d(
      0.99997,
      0,
      0,
      0,
      0,
      0.99997,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.99997,
      0,
      0,
      0,
      0,
      0.99997,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  88.333333% {
    -webkit-transform: matrix3d(
      0.99998,
      0,
      0,
      0,
      0,
      0.99998,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.99998,
      0,
      0,
      0,
      0,
      0.99998,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  90% {
    -webkit-transform: matrix3d(
      0.99998,
      0,
      0,
      0,
      0,
      0.99998,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.99998,
      0,
      0,
      0,
      0,
      0.99998,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  91.666667% {
    -webkit-transform: matrix3d(
      0.99999,
      0,
      0,
      0,
      0,
      0.99999,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.99999,
      0,
      0,
      0,
      0,
      0.99999,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  93.333333% {
    -webkit-transform: matrix3d(
      0.99999,
      0,
      0,
      0,
      0,
      0.99999,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.99999,
      0,
      0,
      0,
      0,
      0.99999,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  95% {
    -webkit-transform: matrix3d(
      0.99999,
      0,
      0,
      0,
      0,
      0.99999,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
    transform: matrix3d(
      0.99999,
      0,
      0,
      0,
      0,
      0.99999,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      1
    );
  }

  96.666667% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  98.333333% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }

  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
