.datagrid {
  .ReactTable {
    .-pagination {
      box-shadow: none;
      border: none;
      font-size: 12px;
      font-weight: 500;
      color: #34495e;

      .-btn {
        background: none;
        border: none;
        font-weight: 500;
      }
    }
    .rt-noData {
      border: 1px solid #1b4f9c;
    }
    .rt-table {
      position: relative;

      .rt-thead {
        .rt-tr {
          background-color: #d6dbdf;
          font-size: 12px;
          font-weight: 500;
          color: #34495e;
          .rt-th {
            padding: 12px;
            text-align: left;
          }
        }
      }
      .rt-tbody {
        .rt-tr {
          background-color: #fff;

          &.-odd {
            background-color: #f9f9f9;
          }

          .rt-td {
            color: #34495e;
            padding: 12px;
            font-size: 12px;

            .dropdown {
              &.btn-group {
                padding: 0px;
                margin: 0px;
                button {
                  color: #000;
                  padding: 0px 5px;
                  margin: 0px;
                  background-color: transparent;
                  border: none;
                  i {
                    padding: 0px !important;
                  }
                }
                .dropdown-toggle {
                  &::after {
                    display: none;
                  }
                }
              }
              &.open {
                button {
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
  &.compact {
    .ReactTable {
      .rt-table {
        .rt-thead {
          .rt-tr {
            .rt-th {
              padding: 10px;
            }
          }
        }
        .rt-tbody {
          .rt-tr {
            .rt-td {
              padding: 5px;
            }
          }
        }
      }
    }
  }
}
