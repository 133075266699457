@import "../../../scss/_variables.scss";

.resources-dashboard {
  .resources-dashboard-title {
    margin: 20px 0;
    color: $base-blue;
    /*hsl(212, 83%, 45%);*/
    /*#509BEF;*/
  }

  .resources-dashboard-section {
    margin: 20px 0;
  }
}
