.blumira-editable {
  display: flex;
  flex-direction: row;

  .blumira-editable-left {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .blumira-editable-invalid {
      margin-top: 5px;
      font-size: 0.5em;
      font-weight: normal;
      font-style: italic;
      color: black;
    }
  }

  .blumira-editable-right {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
}

.blumira-editable.disabled {
  opacity: 0.6;
}
