input {
  &.form-control {
    font-size: 0.8rem;
  }
}

.col {
  padding-left: 0;
  padding-right: 0;
}

.dropdown {
  &.open {
    button.btn {
      background-color: #001a3f !important;
      -webkit-border-radius: 0px !important;
      -moz-border-radius: 0px !important;
      border-radius: 0px !important;
    }

    ul.dropdown-menu {
      display: block;
      padding: 0px;
      right: -10px;
      margin-top: -2px;

      > li,
      a {
        border-bottom: 1px solid $border-color-white;

        &:last-child {
          border-bottom: none;
        }
      }

      a {
        color: $text-color-secondary;
        padding: 5px;
        background-color: #677686;
        text-decoration: none;
        font-size: 0.75rem;
        width: 100%;
        display: block;

        &:hover {
          background-color: #34495e;
        }
      }
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, #ffffff);
  }
}
// @mixin button-variant($background, $border, $hover-background: darken($background, 7.5%), $hover-border: darken($border, 10%), $active-background: darken($background, 10%), $active-border: darken($border, 12.5%)) {

@each $color, $value in $theme-colors {
  .alert-#{$color} {
    @include alert-variant($value, $value, #ffffff);
  }
}
