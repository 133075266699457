@import "../../../../scss/_variables.scss";
@import "./ResourcesSearch.scss";

.resources-search {
  input {
    background-color: $background-color-white;
    padding: 0 0.5rem;
    width: 100%;
  }

  input:focus,
  input.focus {
    background-color: $background-color-white;
  }

  input::placeholder {
    color: hsla(210, 10%, 10%, 0.83);
  }

  .resources-search-prefix {
    text-align: left;
    min-width: 20px;
    max-width: 40px;

    &::before {
      font-family: "Font Awesome 5 Free";
      font-weight: bolder;
      content: "\f002";
      margin: 0px 18px 0 10px;
    }
  }

  .resources-search-input,
  .resources-search-input:focus {
    outline: none;
  }
}
