.tv-mode {
  min-width: 1350px;
  width: 100%;
  padding: 10px;
  background-color: $header-background-color;

  .action-bar {
    color: #fff;

    .logo {
      width: 100%;
      max-height: 120px;
      padding: 15px;
    }
    .action-buttons {
      text-align: right;

      .logout,
      .exit {
        font-size: 100%;
        padding: 3px 10px;
        margin-top: 2px;
        margin-right: 3px;
        color: white;
      }
      .exit {
        background-color: #3482b5;
      }
      .logout {
        background-color: $sec-dark-slate;
      }
    }
  }

  .datagrid {
    .ReactTable {
      .rt-table {
        .rt-thead,
        .rt-tbody {
          .rt-tr {
            .rt-td,
            .rt-th {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }

  .tv-stats {
    color: #fff;
    text-align: center;

    h2 {
      font-size: 2.5rem;
      display: block;
      font-weight: 300;
    }
    h3 {
      font-size: 0.8rem;
      font-weight: 300;
    }
  }

  .grid-wrapper {
    margin-top: 10px;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: $header-background-color;
    color: #444;
  }

  .grid-box {
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;

    .data-viz {
      .title {
        color: $link-primary;
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 5px;
      }

      // TODO: not an amazing solution to the width issue
      .span.full {
        width: inherit !important;
      }

      svg {
        max-height: 23vh;
      }
    }
  }

  .scroller {
    border-top: none;
    border-radius: 5px;
    background: linear-gradient(to right, #034ea2, #cc242c);
    min-height: 15px;
    position: relative;
    padding: 20px;
    visibility: visible;
    opacity: 1;
    transition: height 0.15s linear, visibility 0s, opacity 0.25s linear;
    height: 125px;
    overflow: hidden;

    > .row {
      z-index: 50;
      position: relative;
    }

    .scroller-bg {
      background: -15px -15px url("/images/background_exclamation.png") repeat;
      position: absolute;
      z-index: 49;
      top: 0px;
      left: -90000px;
      right: 0px;
      bottom: 0px;
      width: 100000px;
      pointer-events: none;

      -webkit-animation-name: bg-slide;
      -webkit-animation-duration: 10000s;
      -webkit-animation-timing-function: linear;
      -webkit-animation-iteration-count: infinite;
    }

    h2,
    h3 {
      color: $text-color-secondary;
      font-weight: 300;
      margin: 0;
    }

    h2 {
      font-size: 3rem;
    }
    h3 {
      font-size: 0.8rem;
      font-weight: 300;
    }

    &[data-open="false"] {
      height: 15px;
      transition: height 0.25s linear;
      padding: 10px 20px;

      > div,
      > video {
        visibility: hidden;
        opacity: 0;
        height: 0px;
        padding: 0px;
        margin: 0px;
        transition: margin 0.25s linear, padding 0.25s linear,
          height 0.15s linear, visibility 0.25s linear, opacity 0.25s linear;
      }
    }
  }
}

.a {
  grid-column: 1;
  grid-row: 1;
}
.b {
  grid-column: 2;
  grid-row: 1;
}
.c {
  grid-column: 3;
  grid-row: 1;
}
.d {
  grid-column: 1;
  grid-row: 2;
}
.e {
  grid-column: 2/4;
  grid-row: 2;
}
.f {
  grid-column: 1;
  grid-row: 3;
}
.g {
  grid-column: 2/4;
  grid-row: 3;
}
