@import "../../../scss/_variables.scss";

body.modal-open {
  overflow: visible;
}

.sensor-view {
  padding-bottom: 50px;

  .sensor-header-section {
    min-height: 85px;
    margin-bottom: 25px;
  }

  .sensor-section {
    margin-bottom: 35px;

    .sensor-section-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: $base-blue;
      font-weight: 450;
      font-size: 1.2rem;
      padding: 0 15px;
    }

    .overview-subtitle {
      font-size: 0.85rem;
      font-weight: normal;
    }

    .sensor-section-header.overview {
      flex-wrap: wrap;
      justify-content: start;
      align-items: center;

      .overview-title {
        margin-right: 15px;
      }

      .sensor-labels {
        margin: 0;

        button.label-button {
          color: #6a7b89;

          &:hover {
            color: #8795a1;
          }
        }
        i.fa-tag {
          color: #6a7b89;

          &:hover {
            color: #8795a1;
          }
        }
      }
    }

    .sensor-section-body {
      margin: 15px;
    }
  }
}
