@import '../../../scss/variables.scss'

$finding-border: 1px solid rgba(0,0,0,.12)

.findingDetail
  flex: auto

  > div
    margin-bottom: 20px

  span.label
    font-weight: 500
    display: block


  .evidence
    margin-top: 3px

    .matchedMeta
      b
        padding-right: 8px
        font-weight: 400
        font-size: 14px
      span
        padding-right: 10px
      span + span:not(:last-child)
        font-weight: 400

    .matchedMeta + div
      margin-top: 10px

    .evidenceTables
      font-size: 0.95em
      display: flex
      padding-right: 20px
      table
        border: $finding-border
        border-radius: 2px
        td
          padding: 5px
          height: 35px
          max-width: 20vw
          overflow-wrap: anywhere
          &:not(:last-child)
            border-right: $finding-border
      thead.keys
        color: #333
        font-weight: 400
        background-color: lighten($background-color-grey-accent-lighter, 10%)
        border-bottom: 1px solid $background-color-selected
      tbody.values
        background-color: #ffffff
        width: stretch
        td:nth-of-type(even)
          background-color: lighten($background-color-grey-accent-lighter, 15%)

  .srcCountries
    ul
      list-style: none
      padding: 0
      margin: 0
      margin-top: 7px
      font-weight: 500
      display: inline-flex
      li
        background-color: lighten($background-color-grey-accent-lighter, 10%)
        width: 39px
        padding: 7px
        text-align: center
        margin-right: 10px
  .relatedFinding
    font-size: 0.9em

  .status
    .label
      display: inline
      margin-right: 5px
      &:after
        content: ': '
