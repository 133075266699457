@use "sass:math";

@import "./variables.scss";

// Override variables
$primary: #3482b5;
$secondary: #687784;
$success: #2c9e85;
$info: #3498db;
$warning: #e67e22;
$danger: #e34e47;
$light: #999999;
$dark: #333333;

// Typography
$font-size-base: (math.div(12px, 16px) * 1rem);
$font-size-lg: (math.div(16px, 16px) * 1rem);
$font-size-sm: (math.div(10px, 16px) * 1rem);

@import "bootstrap/scss/bootstrap.scss";
