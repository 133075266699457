.sensor-module-explanation {
  .module-detail-notes,
  .sensor-module-url {
    display: inline;
  }

  .sensor-module-url {
    font-weight: bold;
  }
}
