//---------------------------------------------------------------------------
// Helper Functions
//---------------------------------------------------------------------------
@use "sass:math";

@function pxToRem($size) {
  $remSize: math.div($size, 16px);
  @return $remSize * 1rem;
}

@function map-get-default($map, $key, $default) {
  @if map-has-key($map, $key) {
    @return map-get($map, $key);
  }

  @return $default;
}

//---------------------------------------------------------------------------
// Colors
//---------------------------------------------------------------------------
// Primitive colors (see Style Guide)
$base-blue: #034ea2;
$base-dark-blue: #001c54;
$base-light-blue: #0095da;
$base-red: #c92a36;
$sec-midnight-blue: #001a3f;
$sec-slate: #445868;
$sec-dark-slate: #34495e;
$sec-grey: #333333;
$sec-light-grey: #999999;

// Map primitive colors to semantic applications
$colors: (
  "border-resources": hsla(0, 0%, 0%, 0.08),
  "border-tertiary": #a9a9a9,
  "sec-midnight-blue": $sec-midnight-blue,

  // Text
  "action-link": $sec-dark-slate,
  "resources-link": hsl(220, 72%, 50%),
  // Flyouts / detail
  "detail-bg": #e1e4e7,
  "detail-border": $base-blue,
  // Secondary flyout (stacked)
  "detail-bg-2": #ccd1d7,
  "detail-border-2": $sec-midnight-blue,
);

@function color($name) {
  // Give a default you couldn't possibly miss
  @return map-get-default($colors, $name, "#00FF00");
}

$text-color-main: #222222;
$text-color-active: #ffffff;
$text-color-secondary: #ffffff;
$text-color-inactive: #939ca5;
$text-color-accent: #1b4f9c;

$icon-color: #7f8da7;
$icon-color-grey: #9ba4ad;
$icon-color-grey-darker: #34495e;

$background-color-selected: #1b4f9c;
$background-color-selected-tertiary: #485866;
$background-color-white: #ffffff;
$background-color-selected-darker: #041b3c;
$background-color-grey-accent: #6a7785;
$background-color-grey-accent-lighter: #ccd2d7;

$header-background-color: #041d51;
$header-background-color-darker: #041b3c;

$sidebar-background-color: #eeeeee;

$border-color-secondary: #7f8da7;
$border-color-tertiary: #a9a9a9;
$border-color-white: #ffffff;

$link-primary: #034ea2;
$link-secondary: #0a6aba;
$action-link-color: #34495e;

//---------------------------------------------------------------------------
// Layout
//---------------------------------------------------------------------------
$space-lg: 24px;
$space-md: 20px;
$space-sm: 10px;

$spaces: (
  "flyout-w": 400px,
  "flyout-l-padding": pxToRem($space-lg),
  "flyout-r-padding": pxToRem($space-lg),
  "flyout-section-v-gap": pxToRem($space-lg),
  "lg": pxToRem($space-lg),
  "md": pxToRem($space-md),
  "sm": pxToRem($space-sm),
);

@function space($name) {
  @return map-get-default($spaces, $name, 0);
}

//---------------------------------------------------------------------------
// Typography
//---------------------------------------------------------------------------
// Transferred from Style Guide
$font-sizes: (
  "headline": 24px,
  "card-title": 18px,
  "left-nav-title": 18px,
  "card-actions": 18px,
  "card-descriptions": 20px,
  "card-summary": 16px,
  "body-text": 12px,
  "body-headers": 12px,
  "table-body": 10px,
  "table-headers": 10px,
  // More beyond style guide
  "flyout-body": 12px,
  "flyout-explanation": 12px,
  "alert-body": 16px,
);

$font-weights: (
  "headline": 300,
  "card-title": 500,
  "left-nav-title": 500,
  "card-actions": 300,
  "card-descriptions": 300,
  "card-summary": 500,
  "body-text": 300,
  "body-headers": 700,
  "table-body": 300,
  "table-headers": 700,
  // More beyond style guide
  "flyout-body": 300,
  "flyout-explanation": 300,
  "alert-body": 300,
);

$font-family-primary: "Rubik", "Roboto", sans-serif;

@mixin font($name) {
  font-size: map-get($font-sizes, $name); //Fallback in px
  font-size: pxToRem(map-get($font-sizes, $name));
  font-weight: map-get($font-weights, $name);
}

//---------------------------------------------------------------------------
// Debugging
//---------------------------------------------------------------------------
@mixin debug-map($map) {
  @at-root {
    @debug-map {
      __tostring__: inspect($map);
      __length__: length($map);
      //__depth__: depth($map);
      __keys__: map-keys($map);
      __properties__ {
        @each $key, $value in $map {
          #{'(' + type-of($value) + ') ' + $key}: inspect($value);
        }
      }
    }
  }
}

@include debug-map($spaces);
@include debug-map($font-sizes);
@include debug-map($font-weights);
