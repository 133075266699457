@import "../../../../scss/_variables.scss";

.security-graph-container {
  display: inline-block;
  width: 380px;
  vertical-align: top;

  .security-graph-container-header {
    text-align: center;
    color: $base-blue;
    font-weight: 450;
    font-size: 1.2rem;
  }

  .security-graph-container-subtitle {
    margin-top: 5px;
    text-align: justify;
    color: $sec-grey;
    font-size: 0.65rem;
  }

  .security-no-graph {
    .security-no-graph-text {
      margin: 30px 0;
      text-align: center;
      font-size: 0.85rem;
    }
  }

  .security-graph-caption {
    display: flex;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 35px;
    font-size: 0.85rem;
    justify-content: space-between;

    .security-graph-caption-unit {
      float: left;
      color: $base-blue;
      margin: 0 5px;
    }
  }
}
