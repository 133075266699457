@import "../../../scss/_variables.scss";

.blu-support-header {
  color: $base-blue;
  font-weight: 450;
  font-size: 1.2rem;
  padding: 15px 15px 0 15px;
}

.blu-support-container {
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 0.75rem;
  font-weight: normal;

  .blu-support-container-inner {
    max-width: 800px;
  }

  .blu-support-section {
    padding: 14px 0;
  }

  .blu-support-section-title {
    margin-bottom: 15px;
    font-size: 0.9rem;
  }

  .blu-support-section-body {
    .list-container {
      .row {
        align-items: center;
        min-height: 24px;
        overflow-wrap: break-word;
        padding: 5px 0;
        word-break: break-word;
      }
    }
  }

  .support-item-name {
    font-weight: bold;
  }

  .support-form-container {
    margin: 0 15px;

    .support-form-item {
      margin: 20px 0;
    }

    .support-form-select {
      max-width: 250px;
    }

    .support-form-footer {
      display: flex;

      .support-form-submit-btn {
        min-width: 65px;
        margin-left: auto;
      }
    }

    .support-item-name {
      margin-bottom: 5px;
    }
  }
}
