@import "./SensorModalContent.scss";

.sensor-module-new {
  .sensor-module-explanation {
    padding: 10px;
  }

  .sensor-module-meta-title {
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 0.9rem;
    text-align: center;
  }

  .sensor-module-name {
    margin-top: 5px;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 0.85rem;
    text-align: center;
  }
}
