.tos-view {
  padding: 48px;
  padding-bottom: 90px;
  width: 100%;

  .cancelTosBtn,
  .acceptTosBtn {
    font-size: 1rem;
    margin: 20px;
    width: 80px;
  }

  h3 {
    margin-top: 40px;
    margin-bottom: 25px;
  }

  .lastModified,
  .listItemTitle,
  .mainSection {
    font-weight: 450;
  }

  .lastModified {
    font-size: 1rem;
  }

  .subSection {
    margin-top: 10px;
    margin-bottom: 15px;
  }

  .listItemContent {
    font-weight: 300;
  }

  .listItemTitle {
    font-size: 1.2rem;
    padding-right: 7px;
  }

  .listItem {
    margin-top: 15px;
  }

  .termsOfUse {
    color: $link-primary;
    font-weight: 500;
    font-size: 1.8rem;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
  }
}
