.comments {
  .comment-box {
    margin-top: 24px;
    border: 1px solid #a9a9a9;
    background-color: #fff;
    padding: 0px;

    textarea {
      width: 100%;
      height: 100%;
      padding: 20px;
      border: none;
      outline: none;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: block;

      &:focus {
        outline: none;
      }
    }
  }
}
