.timeline {
  border-top: 1px solid #99a4ae;

  .timeline-event {
    border-bottom: 1px solid #99a4ae;

    .col-8,
    .col-4 {
      padding: 0px;
    }

    .date-wrapper {
      width: 20px;
      background: linear-gradient(to right, #001642, #002371);
      height: 100%;
      display: inline-block;
      vertical-align: top;
      position: absolute;

      .date {
        text-align: center;
        position: absolute;
        top: 18px;
        width: auto;
        left: 0px;
        -webkit-border-top-right-radius: 20px;
        -webkit-border-bottom-right-radius: 20px;
        -moz-border-radius-topright: 20px;
        -moz-border-radius-bottomright: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        background-color: #bd3633;
        color: #fff;
        padding: 10px 10px 8px;
        font-size: 10px;
        width: 65px;
      }
    }

    .text-wrapper {
      height: 100%;
      display: inline-block;
      vertical-align: top;
      padding: 24px 10px 24px 80px;
    }

    .user-wrapper {
      height: 100%;
      display: inline-block;
      vertical-align: top;
      padding: 24px 48px 24px 24px;
    }
  }
}
