.labels-page {
  Loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 50px;
  }

  .labels-page-container {
    position: relative;
  }

  .new-page-header + div.section {
    margin-top: 15px;
  }

  .section {
    margin-bottom: 30px;

    .section-title {
      color: $link-primary;
      font-size: 18px;
      font-weight: 350;
      padding-bottom: 5px;
    }
  }

  .datagrid {
    .ReactTable {
      margin-bottom: 60px;
    }

    .rt-table {
      overflow: visible;
    }
  }
}
