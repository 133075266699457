.new-page-header
  min-height: 35px
  width: 100%
  hr
    margin: 0
  .content
    min-height: 35px
    display: flex
    padding-bottom: 5px
    justify-content: space-between
    .page-header-left
      display: inline-flex
      flex-direction: row
      .page-title
        margin-bottom: -3px
      .page-title h1
        margin: 0
        font-size: 1.4em
        padding-right: 10px
      .page-description
        padding-left: 15px
        border-left: 1px solid #bbb
      .page-description span
        font-size: .75rem
