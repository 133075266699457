@media screen and (max-width: 1200px) {
  .header {
    .alerts {
      display: none;
    }
  }
}

@media screen and (max-width: 1092px) {
  .dashboard {
    .left-column,
    .middle-column {
      &.col-5 {
        flex: 0 0 50% !important;
        max-width: 50% !important;
        width: 50% !important;
      }

      &.col-10 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        width: 100% !important;
      }
    }

    .right-column {
      display: none;
    }

    .scroller {
      visibility: hidden;
      opacity: 0;
      height: 0px;
      padding: 0px;
      margin: 0px;
      transition: margin 0.25s, padding 0.25s, height 0.25s,
        visibility 0.25s linear, opacity 0.25s linear;
    }
  }

  .header {
    .tools {
      display: none;
    }
  }

  .sidebar {
    &[data-menu-closed="false"] {
      width: auto !important;
      min-width: initial !important;

      .sidebar-right-wrapper {
        display: none;
      }
    }

    &[data-menu-closed="true"] {
      .sidebar-left-wrapper {
        width: 72px !important;

        .title {
          display: none !important;
        }
      }

      .sidebar-right-wrapper {
        display: block !important;
      }
    }
  }
}

@media screen and (max-width: 840px) {
  .header {
    .search {
      display: none;
    }
  }

  .event {
    .left-column,
    .right-column,
    .main-content {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      width: 100% !important;
      margin: 10px 0px;
    }

    .main-content {
      .left-column {
        padding-bottom: 20px;
      }
    }

    .flyout {
      width: 100%;
      display: block;
      position: initial;
      float: left;
      z-index: 99;
      overflow: nonel;
      height: 100%;
      border-left: none;

      .flyout-content {
        overflow: none;
        height: auto;
        display: inline-block;
      }

      .footer-gradient {
        display: none;
      }

      .actions {
        position: initial;
        margin-top: 40px;
      }
    }
  }

  .event-card {
    .card-content-header {
      padding-right: 50px !important;
    }
    .card-content-header-right {
      position: absolute;
      top: 10px;
      right: 15px;
    }
  }
}

@media screen and (max-width: 680px) {
  .rdrDefinedRangesWrapper {
    flex: 1;
    border-right: none;
  }

  .dashboard {
    .left-column,
    .middle-column {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      width: 100% !important;
      margin: 10px 0px;
    }
  }

  .filter-selection {
    .filter-header {
      > .text {
        // display: none;
      }
    }
    .popup .rdrCalendarWrapper.rdrDateRangeWrapper {
      display: none;
    }
  }

  .page-header {
    .page-description {
      display: none !important;
    }
  }

  .row.filters {
    .column-header {
      display: block !important;
    }

    .left-column,
    .middle-column {
      &.col-5 {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        width: 100% !important;
      }
    }
  }
}

@media screen and (max-width: 480px) {
}
