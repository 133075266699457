@import "../../../scss/_bootstrap.scss";

.sensor-status {
  display: inline-block;
  padding-right: 10px;
  font-size: 0.8rem;

  .status-online {
    color: $success;
  }
  .status-offline {
    color: $danger;
  }
}
