.dashboard {
  padding: 15px;
  display: block;

  .monitorButton {
    font-size: 0.6rem;
    margin-top: 5px;
    position: absolute;
    right: 0;
    top: -5px;

    button {
      background-color: #3482b5;
    }
  }

  .reports {
    span,
    div {
      font-size: 0.6rem;
    }

    a {
      font-size: 0.6rem;
      color: $link-primary;
      font-weight: 500;

      i {
        color: $icon-color-grey-darker;
        font-size: 0.8rem;
        margin-left: 3px;
      }
    }
  }

  .tv-mode-btn {
    padding: 3px 10px;
    color: white;
    background-color: $base-blue;
  }

  @keyframes bg-slide {
    from {
      -webkit-transform: translateX(0);
    }
    to {
      -webkit-transform: translateX(187%);
    }
  }

  .scroller {
    border-top: 1px solid $border-color-tertiary;
    background: linear-gradient(to right, #034ea2, #cc242c);
    min-height: 15px;
    margin-top: 20px;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    position: relative;
    padding: 20px;
    visibility: visible;
    opacity: 1;
    transition: height 0.15s linear, visibility 0s, opacity 0.25s linear;
    height: 125px;
    overflow: hidden;

    > .row {
      z-index: 50;
      position: relative;
    }

    .scroller-bg {
      background: url("/images/background_exclamation.png") repeat;
      position: absolute;
      z-index: 49;
      top: 0px;
      right: 0px;
      bottom: 0px;
      width: 200%;
      pointer-events: none;
    }

    h2,
    h3 {
      color: $text-color-secondary;
      font-weight: 300;
      margin: 0;
    }

    h2 {
      font-size: 3rem;
    }
    h3 {
      font-size: 0.8rem;
      font-weight: 300;
    }

    &[data-open="false"] {
      height: 15px;
      transition: height 0.25s linear;
      padding: 10px 20px;

      > div,
      > video {
        visibility: hidden;
        opacity: 0;
        height: 0px;
        padding: 0px;
        margin: 0px;
        transition: margin 0.25s linear, padding 0.25s linear,
          height 0.15s linear, visibility 0.25s linear, opacity 0.25s linear;
      }
    }
  }

  .column-header {
    font-weight: 300;
    font-size: 0.6rem;
    padding-bottom: 4px;
    border-bottom: 2px solid $border-color-tertiary;
  }

  .dashboard-content {
    display: flex;
    flex-flow: wrap;
    padding-top: 24px;
    align-content: stretch;

    > div {
      flex: auto;
    }

    svg {
      max-height: 330px;
    }

    .findings {
      display: inline-block;
      width: 57%;
      min-width: 470px;
      padding-right: 3.5%;
    }

    .trending {
      display: inline-block;
      width: 43%;
      min-width: 400px;
    }

    .findings-container {
      height: calc(100vh - 350px);
      overflow-x: visible;
      overflow-y: auto;
      min-height: 750px;
      padding-right: 5px;
    }

    .findings-list {
      overflow-x: visible;
      padding-top: 24px;
      padding-right: 5px;
    }

    .collapse-container {
      border-bottom: 1px solid $border-color-tertiary;
    }

    .right-column {
      .column-content {
        background-color: #e1e4e7;
      }
    }

    .chart-table-container {
      display: flex;
      width: 100%;
      flex-wrap: wrap;

      > .child-chart {
        width: 60%;
        min-width: 700px;
        max-width: 1100px;
        flex-grow: 1;
      }

      > .child-table {
        min-width: 550px;
        flex-grow: 1;
      }
    }
  }
}
