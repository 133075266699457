@import "../_variables.scss";

.avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 27px;
  min-width: 27px;
  max-width: 27px;
  min-height: 27px;
  max-height: 27px;

  background-color: $base-light-blue;
  color: white;
  font-size: 9px;

  img.avatar-content {
    min-width: 27px;
    max-width: 27px;
    min-height: 27px;
    max-height: 27px;
  }
}
