.event-card {
  margin: 20px 0px;
  width: 100%;
  height: auto;
  position: relative;

  .card-flags {
    width: 25px;
    text-align: center;
    min-width: 20px;

    > span {
      text-align: center;
      display: block;
      background-color: $background-color-grey-accent-lighter;
      color: $text-color-secondary;
      font-weight: 100;
      font-size: 0.8rem;
      padding: 5px 0px;
      border-bottom: 1px solid $border-color-white;

      &:first-child {
        -webkit-border-top-left-radius: 5px;
        -moz-border-radius-topleft: 5px;
        border-top-left-radius: 5px;
      }

      &:last-child {
        -webkit-border-bottom-left-radius: 5px;
        -moz-border-radius-bottomleft: 5px;
        border-bottom-left-radius: 5px;
        border-bottom: none;
      }
    }

    &.card-flags-explanations {
      position: absolute;
      left: 27px;
      top: 0px;
      z-index: 9;
      width: 100px;
      border: none;

      > span {
        -webkit-border-radius: 0px;
        -moz-border-radius: 0px;
        border-radius: 0px;
        text-align: left;
        padding-left: 10px;
        background-color: $background-color-selected-darker;
      }
    }
  }

  .card-content {
    border-left: 4px solid $background-color-selected;
    overflow: hidden;

    .card-content-header {
      padding: 10px 15px;
      color: $text-color-secondary;
      background-color: $background-color-grey-accent;
      position: relative;

      .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .card-content-header-left {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .card-content-header-right {
        text-align: right;

        .caret,
        .dropdown-toggle::after {
          display: none;
        }

        button {
          padding: 0;
        }

        .dropdown {
          ul.dropdown-menu {
            li {
              text-align: right;
            }
          }
        }
      }
    }

    .card-content-info {
      background-color: $background-color-white;
      font-size: 12px;
      border-bottom: 1px solid $border-color-tertiary;
      border-top: 1px solid $border-color-tertiary;
      padding: 0px;
      white-space: nowrap;
      text-overflow: ellipsis;

      .card-content-info-left,
      .card-content-info-middle,
      .card-content-info-right {
        width: 33.33%;
        padding: 6px 12px;
        font-size: 12px;
        border-right: 1px solid #99a4ae;
        white-space: nowrap;
        text-overflow: ellipsis;

        > div {
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .card-content-info-middle,
      .card-content-info-right {
        > div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      span {
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .card-content-sub-events {
      .sub-event {
        margin: 0px 0px 10px;
      }

      .analysis {
        margin-bottom: 20px;
        font-size: 14px;
      }

      .number-bubble {
        border: 1px solid #ccc;
        border-radius: 40px;
        padding: 5px;
        width: 30px;
        display: inline-block;
        text-align: center;
        background-color: #eee;
      }
    }

    > .card-content-requestor {
      font-size: 0.65rem;
      font-weight: 400;
      color: #38495c;
      padding-top: 10px;
      padding-bottom: 10px;
      background-color: $background-color-grey-accent-lighter;
      border-bottom: 1px solid $border-color-tertiary;

      > span {
        color: #38495c;
      }
    }

    > .card-content-sub-events {
      background-color: $background-color-white;
      padding: 20px 0px;
      font-size: 0.8rem;
    }

    > .card-content-message {
      background-color: lighten($background-color-grey-accent-lighter, 10%);
      padding: 10px 0px 0px;
      font-size: 0.8rem;

      textarea {
        width: calc(100% - 25px);
        height: 30px;
        border: none;
        background: transparent;
        resize: none;
      }

      .attachment {
        cursor: pointer;
        position: absolute;
        top: 5px;
        right: 10px;
        font-size: 1rem;
        color: $icon-color-grey;
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
      }
    }
  }

  &[data-inverted="true"] {
    .card-flags {
      border-right: 4px solid #1b4f9c;
      height: 100%;

      &.card-flags-explanations {
        border-right: none;
        left: 72px;
      }
    }

    .card-content {
      border-left: none;

      .card-content-header {
        background: transparent;
        color: $text-color-main;
        padding: 0px 24px 24px;

        .title {
          font-size: 24px;
          color: #034ea2;
        }

        .btn-primary {
          color: inherit;
        }

        .dropdown {
          &.open {
            i {
              color: #fff;
            }
          }
        }
      }

      .card-content-info {
        margin: 0px 9px;
        border-top: 1px solid $border-color-tertiary;
        padding: 0px;

        .card-content-info-left,
        .card-content-info-middle,
        .card-content-info-right {
          width: 33.33%;
          padding: 6px 12px;
          font-size: 12px;
          border-right: 1px solid #99a4ae;
        }
      }
    }

    .card-content-expanded {
      .current-action {
        border-top: 1px solid #99a4ae;
        border-bottom: 1px solid #99a4ae;
        background-color: #d6dbdf;
        font-size: 13px;
        font-weight: 500;
        color: #34495e;
        padding: 10px 20px;
      }
    }
  }

  h4 {
    color: #034ea2;
    font-size: 18px;
  }
}
