@import "../../scss/_variables.scss";

.security-dashboard-container {
  .security-select-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    width: 100%;
    margin: 0 15px;
  }

  .security-top-graph-container {
    text-align: center;

    .security-graph-container {
      min-width: 450px;
      width: 100%;
    }
  }

  .security-dashboard-footer {
    width: 100%;
    border-bottom: 1px solid $border-color-tertiary;
    padding: 20px;
    text-align: center;
    font-size: 12px;
  }
}
