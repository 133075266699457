.empty-visualization {
  position: relative;

  .empty-visualization-inner {
    position: absolute;
    width: 100%;
    text-align: center;
    z-index: 10;

    .message {
      top: 35%;
      font-size: 36px;
      display: block;
      font-weight: 400;
      color: $sec-slate;
    }

    .error-message {
      margin-top: 20px;
      text-align: center;
      font-size: 36px;
      display: block;
      font-weight: 400;
      color: $sec-dark-slate;
    }

    .error-retry {
      margin: auto;
      margin-top: 20px;
      background-color: $sec-dark-slate;
      border: 1px solid $sec-light-grey;
      cursor: pointer;
      display: block;
      color: white;
      font-size: 25px;
    }
  }

  .blurred-container {
    filter: blur(5px);

    .white-tinting-container {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 100;
      background-color: white;
      opacity: 0.4;
    }
  }
}
