.slider {
  width: 100%;

  h4 {
    color: #034ea2;
    font-size: 18px;
  }

  .bar {
    width: 100%;
    height: 15px;
    margin-top: 40px;

    .section {
      height: 100%;
      width: 20%;
      display: inline-block;

      &:first-child {
        -webkit-border-top-left-radius: 5px;
        -webkit-border-bottom-left-radius: 5px;
        -moz-border-radius-topleft: 5px;
        -moz-border-radius-bottomleft: 5px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:last-child {
        -webkit-border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-radius-topright: 5px;
        -moz-border-radius-bottomright: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  .indicator {
    position: relative;
    top: -46px;
    width: 30px;
    height: 35px;
    color: #fff;
    padding: 5px 10px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid #fff;

    .arrow {
      position: absolute;
      width: 10px;
      height: 10px;
      bottom: -6px;
      right: 9px;
      border-bottom: 1px solid #fff;
      border-right: 1px solid #fff;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .description {
    font-weight: 500;
    color: #001c54;
    font-size: 12px;
  }
}
