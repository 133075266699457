.dashboard.sensors
  height: 100%

  > .row.main-content
    display: flex
    flex-direction: row
    margin: 0

  .total-sensors-count
    width: 100%
    margin-top: 15px
    font-size: 15px
    text-align: right
    padding: 5px
    font-weight: 500
    background-color: #eee
    border-bottom: 1px solid #34495e
    color: #34495e


  .loading-div
    display: flex
    align-items: center
    justify-content: center
    height: 100%
    width: 100%
    z-index: 1

  .sensors-table
    width: 100%

    .datagrid

      .ReactTable
        margin-bottom: 60px

      .rt-table
        overflow: visible

  .sensors-alert
    margin-bottom: 20px
