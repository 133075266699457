@import "../../../scss/_bootstrap.scss";
@import "../../../scss/_bootstrap_overrides.scss";

.labels-container {
  display: inline-block;
  position: relative;
  font-size: 0.9rem;
  font-weight: 300;

  /* Do not display the select shevron, since the select is always open */
  svg,
  span {
    display: none;
  }

  .loading-div {
    display: inline-block;
    color: $primary;
  }

  .labels-select-backdrop {
    position: absolute;
    top: 25px;
    width: 320px;
    height: 420px;
    background-color: #e9ebed;
    box-shadow: 2px 2px 7px 0px gray;
    z-index: 11;

    .labels-select-header {
      width: 100%;
      height: 50px;

      .restrict-checkbox,
      a {
        line-height: 50px;
      }

      .restrict-checkbox {
        float: left;
      }

      a {
        float: right;
      }
    }

    .labels-error-alert {
      text-align: center;
      margin-bottom: 0;
    }

    .labels-select {
      color: #000000;
      width: 100%;
      height: 370px;
      border-radius: 2px;
      padding: 10px 10px 0 10px;
      z-index: 9999;
    }
  }

  .restrict-checkbox {
    width: 150px;
    height: 100%;
    margin-left: 10px;
    color: $primary;

    .restrict-checkbox-checkbox,
    .restrict-checkbox-label {
      display: inline-block;
      vertical-align: middle;
    }

    .restrict-checkbox-checkbox {
      width: 15px;
      height: 15px;
      margin-right: 10px;
    }
  }

  .restrict-checkbox-checkbox:hover {
    transform: scale(1.2);
  }

  .manage-link {
    width: 70px;
    margin-right: 10px;
    padding: 5px;
    background-color: $primary;
    color: $text-color-secondary;
  }
}
