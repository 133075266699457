.main-content-wrapper > .my-alerts-view {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.my-alerts-view {
  padding: 20px 5px 20px 20px;

  .field {
    margin-bottom: 10px;
    width: 50%;
    padding-right: 15px;
    min-width: 250px;
    flex-grow: 1;
  }

  .form-group:not(:first-of-type) {
    padding-right: 15px;
  }

  h2 {
    font-size: 1.2rem;
    font-weight: 300;
  }
}
