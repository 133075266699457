@import "../../scss/_variables.scss";

.notification-form {
  .notification-option {
    width: 100%;
    height: 100%;
    display: inline-block;
    cursor: pointer;
  }
  .notification-option[disabled] {
    cursor: not-allowed;
  }

  .notification-option:hover input[type="checkbox"]:enabled {
    cursor: pointer;
    transform: scale(1.2);
  }
  .notification-option:hover input[type="checkbox"]:disabled {
    cursor: not-allowed;
    transform: scale(1);
  }

  .finding-comment-notification,
  .initial-responder-notification {
    color: #3e7ef8; // AZURE blue from theme

    span.blumira-form-help {
      vertical-align: super;
      font-size: 0.8rem;
    }

    .notification-option {
      width: 40px;
    }
  }
}
