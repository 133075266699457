.error-banner {
  .error-banner-message {
    margin-bottom: 15px;
  }

  .error-banner-btn {
    display: flex;
    flex-direction: row;

    button {
      margin-left: auto;
    }
  }
}
