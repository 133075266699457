form,
.form {
  .row {
    margin: 0px;
    padding: 0px;
    margin-top: 15px;
  }

  .form-group {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .field {
    .control-label {
      width: 100%;
      display: inline-block;
      margin: 0px;
      vertical-align: top;
      color: $link-primary;
      font-weight: 500;
      font-size: 12px;
    }

    .control-item {
      .user-email {
        min-width: 280px;
      }
      .role {
        text-transform: capitalize;
      }
    }

    &.checkbox {
      input[type="checkbox"] {
        opacity: 0;
        display: none !important;
      }

      label {
        position: relative;
        display: inline-block;
        padding-left: 30px;
      }

      label::before,
      label::after {
        position: absolute;
        content: "";
        display: inline-block;
      }

      label::before {
        height: 20px;
        width: 20px;
        border: 1px solid #aaa;
        left: 0px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        top: 0px;
      }

      label::after {
        height: 7px;
        width: 15px;
        border-left: 3px solid;
        border-bottom: 3px solid;
        transform: rotate(-45deg);
        left: 3px;
        top: 5px;
      }

      input[type="checkbox"] + label::after {
        content: none;
      }

      input[type="checkbox"]:checked + label::after {
        content: "";
        color: #034ea2;
      }

      input[type="checkbox"]:focus + label::before {
        outline: rgb(59, 153, 252) auto 5px;
      }
    }

    &.error {
      .control-label,
      .inline-control-label {
        color: red;
      }

      input,
      input[type="checkbox"],
      input[type="checkbox"] + label::after {
        border: 1px solid red !important;
        background-color: pink !important;
      }

      input[type="checkbox"] {
        padding: 10px;
        font-size: 12px;
      }
    }

    &.select-field {
      min-block-size: fit-content;
      label {
        margin-bottom: 5px;
      }

      input {
        border: inherit !important;
        background-color: inherit !important;
      }
    }

    .error-message {
      color: red;
      font-size: 10px;
      margin-left: 10px;
    }

    .flyout-text-field::placeholder {
      color: $text-color-inactive;
    }
  }

  // @TODO: this code is the same as control-label, any good way to share?
  .group-control-label {
    width: 100%;
    display: inline-block;
    margin: 0px;
    vertical-align: top;
    color: #034ea2;
    font-weight: 500;
    font-size: 12px;
  }
}

.flyout-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

// auth0-login-form
.mx-auto,
.mx-auto form {
  display: block;
}
