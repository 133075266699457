@import "../../../scss/_bootstrap.scss";
@import "../../../scss/_bootstrap_overrides.scss";
@import "../../../scss/_variables.scss";

.sensor-installation-container {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;

  .overview-cell {
    white-space: normal;
    word-wrap: break-word;
  }

  .overview-cell-bold {
    font-weight: bold;
  }

  .overview-ul {
    padding-left: 15px;
  }

  .installation-script {
    display: block;
    overflow-wrap: normal;
    word-break: break-all;
    white-space: pre-wrap;
    margin-left: 1em;
    margin-right: 1em;
    padding: 10px;
    border-radius: 5px;
    background: #000;
    color: #fff;
  }
}
