.pipelines-page-view {
  .refresh-btn {
    min-width: 82px;
    min-height: 32px;

    .refresh-text-span {
      margin-left: 7px;
    }
  }

  .refresh-btn.btn:focus,
  .refresh-btn.btn.focus {
    box-shadow: none;
  }
}
