@import "./variables.scss";
@import "./bootstrap.scss";
@import "./bootstrap_overrides.scss";
@import "./quill_overrides.scss";
@import "./layout.scss";
@import "./login.scss";
@import "./pages.scss";
@import "./components.scss";
@import "./media.scss";
@import "./initial_tos.scss";
@import "./loading.scss";

button:focus {
  outline: 0 !important;
}

// hardcoding the following text color in for old modals
// specifically so that the text is still readable when viewing the site in dark mode
// otherwise it defaults to whatever the body text color is which is too light against the old modals white backgrounds
.modal-header:not(.sensor-modal-header) .modal-title,
.modal-body {
  color: #3b3b3b;
}
