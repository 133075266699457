.loading {
  background-color: #f4f6f8;

  .col {
    text-align: center;

    img {
      width: 100%;
      min-width: 200px;
      max-width: 400px;
    }

    h2 {
      font-weight: normal;
      margin: 20px 0 30px;
    }
  }
}
