.logdevs-custom-tooltip {
  background: white;
  padding: 9px 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  .logdevs-custom-tooltip-title {
    display: inline-block;
    text-align: center;
    min-width: 20px;
    max-width: 20px;
    margin-right: 5px;
  }
}
