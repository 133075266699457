.detail {
  overflow-wrap: break-word;
  word-break: break-word;

  .title {
    color: $base-blue;
    font-size: 18px;
    font-weight: 300;
  }

  .subtitle {
    font-size: 12px;
  }

  p {
    font-weight: 300;
    font-size: 12px;
    color: #34495e;
    margin: 0px;
  }

  h5 {
    font-weight: 500;
    font-size: 12px;
    color: $base-blue;
    margin-top: 24px;
    margin-bottom: 12px;
  }

  .itemName {
    font-weight: 500;
    font-size: 12px;
    color: $base-blue;
    min-width: 70px;
    display: inline-flex;
  }

  .itemContent {
    color: color("action-link");
    font-weight: 300;

    .role {
      text-transform: capitalize;
    }
  }

  .actions {
    text-align: center;
    width: 100%;

    button {
      &:focus {
        outline: none;
      }
      &:first-child {
        margin-right: 10px;
      }
      &:last-child {
        margin-left: 10px;
      }
    }
  }

  .notification-form {
    h2 {
      color: $text-color-accent;
      font-size: 0.75rem;
      font-weight: 300;
    }
  }

  .footer-gradient {
    background: linear-gradient(
      to bottom,
      rgba(204, 210, 215, 0),
      rgba(204, 210, 215, 1)
    );
    position: absolute;
    z-index: 9999;
  }
}
