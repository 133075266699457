@import '../../../scss/variables.scss'

$box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.15)
$box-shadow: $box-shadow, 0px 1px 1px 0px rgba(0,0,0,0.14)
$box-shadow: $box-shadow, 0px 2px 1px -1px rgba(0,0,0,0.12)

.full-width-finding
  box-sizing: border-box
  display: grid
  grid-template-areas: 'header' 'main' 'messages' 'evidence'
  margin-bottom: 28px
  margin-left: auto
  margin-right: auto
  height: fit-content
  width: 100%
  padding: 0
  font-size: 13px
  font-weight: 300

  .sampleFindingWarning .MuiAlert-icon
    margin-top: 3px

  > *
    border: 1px solid rgba(0, 0, 0, 0.12)
    box-shadow: $box-shadow
    border-radius: 1px

  .finding-tags
    display: flex
    .labels-container button.label-button
      color: #ffffff
      display: flex
      padding: 0 10px 0 0
      &:hover
        color: #cccccc
    .labels-container i.fa-tag
      align-self: self-end
      font-size: 14px
      color: #ffffff
      margin-right: 5px
      &:hover
        color: #cccccc
    .tags-container
      line-height: 15px
      display: inline-block
      font-size: 10px
      align-self: normal
      .tag-btn
        margin: 0 5px 0 0
        .tag-name
          display: block
          line-height: 8px
          padding: 5px
        .tag-delete
          .delete-btn
            padding: 4px 5px
          i.fa-times
            disply: block
            font-size: 10px

  header
    grid-area: header
    border-top: 1px solid rgba(0, 0, 0, 0.12)
    margin-bottom: 15px
    background-color: #1b4f9c
    padding: 7px 13px

    .detection-type
      margin: 5px 0

    .header-first-row, .name, .detection-type, .finding-tags
      line-height: 20px
      align-items: center

    .header-first-row, .name, .detection-type, .finding-tags
      display: flex
      justify-content: flex-start
      padding: 0
    
    .col1
      width: 170px
      color: #ffffff
      font-weight: 600
      font-size: 1rem

    .col2
      color: #ffffff
      font-weight: 400
      font-size: 1rem

    .header-first-row
        justify-content: space-between

        .short-id
          color: #ffffff
          font-weight: 400
          font-size: 1rem

        .blocklists-btn-container
          width: 115px
          min-width: 115px
          margin-left: 5px

          .view-blocklists-btn
            background-color: #ffffff
            color: #677686
            &:hover
              color: #c0392b

  .messages
    grid-area: messages

  div#main
    grid-area: main

  section#evidence
    grid-area: evidence
    display: flex
    overflow: auto
    min-height: 250px

  div#main, section#evidence
    padding: 25px


  .flags
    display: flex
    flex: auto
    text-align: center
    flex-direction: row
    height: 32px
    color: white

    div
      border: 0
      border-radius: 4px
      display: inline-block
      font-size: 16px
      font-weight: 500
      height: 32px
      line-height: 32px
      margin-right: 5px
      min-width: 100px
      width: 110px

    .flag-type-10
      background-color: #034ea2

    .flag-type-15
      background-color: #35027b

    .flag-type-20
      background-color: #683967

    .flag-type-30
      background-color: #9b2f49

    .flag-type-40
      background-color: #c0392b

    .flag-priority-1, .flag-type-alert
      background-color: #c0392b

    .flag-priority-2
      background-color: #e67e22

    .flag-priority-3
      background-color: #f1c40f


  .infoTitle
    display: block
    text-transform: uppercase
    font-size: 16px
    font-weight: 500


  .main-content

    .info
      min-height: auto
      display: grid
      grid-auto-columns: auto
      grid-template-columns:  40%
      grid-template-rows: auto
      grid-column-gap: 20px
      grid-template-areas: "left workflows"

      .left
        grid-area: left
      .general
        grid-area: general

        .type
          box-sizing: border-box
          justify-content: center
          display: inline-flex
          font-size: 20px
          font-weight: 400

      .date, .type, .status, .owners, .flags, .analysis
        margin-bottom: 10px
      .date, .type, .status, .flags
        font-size: 16px
      .date, .status
        display: inline-flex
        vertical-align: middle
        .infoTitle
          display: inline
          padding-right: 8px

      .owners
        grid-area: owners
        text-align: left
        font-weight: 400

        label
          align-items: center
          display: flex
          width: 100%
          margin: 0

      .analysis
        grid-area: analysis

      .workflows
        grid-area: workflows

        .workflow .question .questionRow .questionText
          padding: 10px

      .date span
        font-weight: 500
        text-transform: uppercase

      .owner-selector
        display: inline-block
        width: 100%

      .completedWorkflows .workflow::before
        content: "completed workflow"

    .info-blocked
      grid-template-columns: 100%

  #usercontent
    min-height: auto
    display: grid
    grid-auto-columns: auto
    grid-template-columns: 70%
    grid-template-rows: auto
    grid-column-gap: 20px
    grid-template-areas: "messages attachments"

    .messages
      grid-area: messages
    .attachments
      grid-area: attachments

  .messages
    font-weight: 400

    .message-error
      margin-bottom: 15px
      color: $base-red
      font-size: 0.9rem

    .composer
      min-height: 35px
      padding: 16px 20px

    span.placeholder
      color: #999
      display: block
      line-height: 14px
      min-height: 14px

    .ql-toolbar
      padding-bottom: 0

    .ql-editor
      height: 100px
      padding-top: 5px

    .quill
      border: 1px solid #79869c

  .findingMessages.hasComments
    padding: 15px

    div.comment
      border-left: 2px solid #1b4f9c
      box-sizing: border-box
      font-size: 14px
      margin-bottom: 15px
      padding-left: 5px

      .ql-font-monospace
        font-family: Monaco, Courier New, monospace

      .ql-font-serif
        font-family: Georgia, Times New Roman, serif

      .commentLabel
        font-size: 13px

        .commentSender
          font-weight: 500
          padding-right: 6px

          + span
            color: #666

  .finding-support

    .blu-support-container
      padding-top: 10px
      padding-left: 30px
      border: none

  .attachments
    padding: 12px 16px 12px 0

    .attachment
      padding: 8px 0 4px 0
      margin: 0
