.collapse-container {
  height: fit-content;
  margin: 15px;

  background-color: $background-color-white;
  min-height: 75px;
  font-size: 16px;
  padding: 20px;

  .collapse-title {
    color: $link-primary;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;

    i.fas {
      color: $sec-grey;
      float: right;
      font-size: 1.2em;
    }
  }
  .collapse-content {
    padding-top: 12px;
    height: 200px;
    opacity: 1;
    visibility: visible;
    transition: padding 0.2s linear, opacity 0.2s linear, height 0.2s linear,
      visibility 0.2s linear;

    &[data-open="false"] {
      height: 0px !important;
      opacity: 0;
      visibility: hidden;
      transition: padding 0.2s linear, opacity 0.2s linear, height 0.2s linear,
        visibility 0.2s linear;
    }
  }
}
