@import "../../../scss/_bootstrap.scss";
@import "../../../scss/_bootstrap_overrides.scss";
@import "../../../scss/_variables.scss";

.blocking-devices {
  padding: 10px 15px;
  border: 1px solid $border-color-secondary;

  .blocking-devices-row {
    min-height: 40px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .blocking-devices-label,
  .blocking-devices-url {
    display: flex;
    align-items: center;
    min-width: 80px;
    font-size: 0.85rem;
  }

  .blocking-devices-label {
    max-width: 220px;
    padding-left: 0;
    padding-right: 10px;
    font-weight: bold;
  }

  .blocking-devices-url {
    padding: 0 7px;
    border: 2px solid $border-color-secondary;
    background-color: white;

    input,
    input:focus {
      width: 100%;
      border: 0;
      outline: none;
      white-space: nowrap;
    }
  }
}
