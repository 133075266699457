@import "../../../scss/_bootstrap.scss";
@import "../../../scss/_bootstrap_overrides.scss";
@import "../../../scss/_variables.scss";

.sensor-devices-container {
  position: relative;

  .graph-container {
    height: 350px;
    margin-bottom: 30px;
  }

  .devices-cell {
    white-space: normal;
    word-wrap: break-word;
  }

  .devices-cell-bold {
    font-weight: bold;
  }

  .devices-ul {
    padding-left: 15px;
  }

  .devices-loading-div {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    z-index: 9999;
  }

  .rt-resizable-header-content {
    white-space: normal;
  }
}

.devices-select-container,
.devices-summary-container {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;

  width: 100%;
  min-width: 300px;
  min-height: 40px;

  padding: 0 15px;

  font-size: 0.85rem;

  .container-left {
  }

  .devices-container-right {
    margin-left: auto;
  }
}

.devices-select-container {
  position: relative;
  color: #34495e;

  margin: 0;
  overflow: visible;

  .devices-select-header,
  .devices-select-body {
    display: inline-block;
    min-width: 150px;
    height: 40px;
    z-index: 3;
  }

  .devices-select-body {
    margin-left: auto;
  }

  .devices-select-header {
    line-height: 40px;
  }
}

.devices-summary-container {
  color: #34495e;

  .devices-summary-item {
    display: inline-flex;
    align-items: flex-end;
    margin-right: 15px;
    padding-bottom: 5px;
    min-height: 35px;
    min-width: 150px;

    .bold {
      margin-left: 5px;
      font-weight: bold;
    }
  }
}
