.tags {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 27px;

  .tag,
  .icon {
    font-size: 0.5rem;
    padding: 5px;
    margin-right: 5px;
    cursor: pointer;
  }

  .tag {
    background-color: #ccd3d6;
    border: 1px solid #6a7b89;
    box-sizing: border-box;
    color: $background-color-grey-accent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
  }
}
